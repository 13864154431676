import { Box } from '@mui/material';
import LogoSvg from '../images/expeditionnimrod.svg';

const ExpeditionNimrodLogo = ({ sx }) => (
    <Box
        component="img"
        src={LogoSvg}
        alt="Expedition Nimrod logo"
        sx={sx}
    />
)

export default ExpeditionNimrodLogo;