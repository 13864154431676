import React from 'react';
import {
    responsiveFontSizes, Grow, Container,
    ThemeProvider, Grid, Typography, Box, useTheme, Divider,
    Link
} from '@mui/material';
import useStyles from '../styles/styles';
import mountain_pic from '../images/mountain-pic.jpg';
import ExpeditionNimrodLogo from './ExpeditionNimrodLogo';



const Company = () => {
    let theme = useTheme();
    theme = responsiveFontSizes(theme);
    const classes = useStyles();
    const sectionItems = [
        {
            id: 1,
            icon: <ExpeditionNimrodLogo />,
            sentence1:
                'Delivering modern software tools to manufacturers',
            sentence2:
                'Striving to be honest + ambitious + humble',
            // sentence3:
            //     '📣	Receive alerts instantly when important scenarios are detected.',
            // sentence4:
            //     '📋 Schedule PDF reports periodically.',
            // sentence5:
            //     '🔒 Data is securely gathered and stored automatically, with no need for human intervention.',
            // sentence6:
            //     '🚀 Connect to any number of machines.',

        },
    ];

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg" sx={{ mt: "10px", mb: "30px" }}>
                <Grid container className={classes.sectionGridTitleContainer}>
                    <Grow in={true} timeout={500} style={{ transformOrigin: '0 0 0' }}>
                    <Typography variant="h3" color={'secondary'} sx={{ mt: "10px", mb: "30px" }}><span style={{ fontFamily: 'Manrope', letterSpacing: '-0.05rem', }} className={classes.sectionTitle}>Company</span> Overview</Typography>
                    </Grow>
                </Grid>
                <Grid container
                    spacing={0}
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Grow in={true} timeout={600} style={{ transformOrigin: '0 0 0' }}>
                    <Grid item xs={3} sm={2} md={1.5}>
                        <Grid spacing={2}>
                            <Grid item >
                                <Box>
                                    <ExpeditionNimrodLogo />
                                </Box>
                            </Grid>
                            <Grid item sx={{ mt: "15px", mb: "15px" }}>
                                <Typography
                                    variant="h6"
                                    align="right"
                                    fontFamily='Manrope'
                                    letterSpacing='-0.05rem'
                                    lineHeight={1}
                                // className={classes.sectionTitle}
                                >
                                    Expedition Nimrod
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    </Grow>
                    <Divider orientation="vertical" sx={{ ml: "15px", mr: "15px" }} flexItem />
                    <Grow in={true} timeout={600} style={{ transformOrigin: '0 100 0' }}>
                    <Grid item xs={6} sm={4} md={4}>
                        <Grid
                            spacing={0}
                            justifyContent="center"
                            alignItems="center">
                            <Grid item>
                                <Typography
                                    variant="h5"
                                >
                                    Delivering modern software tools to manufacturers
                                </Typography>
                            </Grid>
                            <Divider sx={{ mt: "15px", mb: "15px" }} />
                            <Grid item>
                                <Typography
                                    variant="h5"
                                    fontWeight={700}>
                                    Striving to be honest + ambitious + humble
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    </Grow>
                </Grid>
                <Grid container
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mt: "15px", }}>
                    <Grid item sm={7} md={6}>
                        <Grid spacing={2}>
                            <Grid item >
                                {/* <Box
                                    component="img"
                                    src={mountain_pic}
                                /> */}
                                <img 
                                    src={mountain_pic}
                                    alt="Expedition Nimrod inspiration from the Nimrod Expedition"
                                    className={classes.heroImage}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={7} md={2.5}>
                        <Typography
                            variant="h6"
                        >
                            We are named after the <Link href='https://en.wikipedia.org/wiki/Nimrod_Expedition' target='_blank'>Nimrod Expedition</Link> that Ernest Shackleton led to the Antarctic from 1907-1909.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider >
    )
}

export default Company;