import React from 'react';
import {
  Card, CardActionArea, CardMedia,
  Grid, Typography, Box, Container, Grow,
  responsiveFontSizes, ThemeProvider, useTheme
} from '@mui/material';
import useStyles from '../styles/styles'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Skeleton from '@mui/material/Skeleton';
import Star from '../styles/icons';
import report_pic from '../images/Report2.jpg';
import mobile_report_pic from '../images/Report2.jpg';


function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Section = () => {
  let theme = useTheme();
  theme = responsiveFontSizes(theme);
  const classes = useStyles();
  const demos = {
    peakpro:
      '<iframe width="100%" height="750px" scrolling="no" frameborder="no" src="https://demo.expeditionnimrod.com/d/e4710610-b745-4df9-9dbe-cff9d56942c1/website-snapshot-dashboard?orgId=1&refresh=1s&kiosk&output=embed"></iframe>',
    peakpro_mobile:
      '<iframe width="100%" height="1055px" scrolling="no" frameborder="no" src="https://demo.expeditionnimrod.com/d/e4710610-b745-4df9-9dbe-cff9d52942c1/website-snapshot-dashboard-mobile?orgId=1&refresh=1s&kiosk&output=embed"></iframe>'
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg"
        sx={{
          mt: "30px",
          paddingTop: '20px',
          paddingBottom: '30px',
          // background: "linear-gradient(180deg, rgba(256,214,10,0.5),transparent)"
        }}
      ><Grow in={true} timeout={800} style={{ transformOrigin: '0 100 100' }}>
      <Box
        id="learn-more"
        sx={{ flexGrow: 0, minHeight: '100px' }}
      >
        <Grid container className={classes.sectionGridTitleContainer}>
            <Typography
              variant="h3"
              color={'secondary'} sx={{ mt: "10px", mb: "10px" }}>
                See <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.1rem', }}> PeakPro </span>Features</Typography>
        </Grid>
          <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', display: { xs: 'none', sm: 'block' } }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs"
                  centered>
                  <Tab style={{ minWidth: "20%" }} icon={<ContentPasteIcon sx={{ fontSize: '40px' }} />} label="Reporting" sx={{ fontWeight: 900, fontSize: 'large' }}{...a11yProps(0)} />
                  <Tab style={{ minWidth: "20%" }} icon={<QueryStatsIcon sx={{ fontSize: '40px' }} />} label="Dashboards" sx={{ fontWeight: 900, fontSize: 'large' }}{...a11yProps(1)} />
                  <Tab style={{ minWidth: "20%" }} icon={<NotificationsNoneIcon sx={{ fontSize: '40px' }} />} label="Alerts" sx={{ fontWeight: 900, fontSize: 'large' }}{...a11yProps(2)} />
                </Tabs>
              </Box>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', display: { xs: 'block', sm: 'none' } }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs"
                  centered>
                  <Tab style={{ minWidth: "5%" }} icon={<ContentPasteIcon sx={{ fontSize: '28px' }} />} label="Reporting" sx={{ fontWeight: 900, fontSize: 'small' }}{...a11yProps(0)} />
                  <Tab style={{ minWidth: "5%" }} icon={<QueryStatsIcon sx={{ fontSize: '28px' }} />} label="Dashboards" sx={{ fontWeight: 900, fontSize: 'small' }}{...a11yProps(1)} />
                  <Tab style={{ minWidth: "5%" }} icon={<NotificationsNoneIcon sx={{ fontSize: '28px' }} />} label="Alerts" sx={{ fontWeight: 900, fontSize: 'small' }}{...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Grid container
                  spacing={2}
                  direction="row-reverse"
                  justifyContent="center"
                  alignItems="flex-start">
                  <Grid item xs={12} sm={5}>
                    <Grid container className={classes.sectionGridItem} sx={{
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                      mb: "30px",
                    }}>
                      <Typography variant="h5">
                        <Star /> Instantly generate simple or advanced PDF reports that are great for printing and record keeping.
                      </Typography>
                    </Grid>
                    <Grid container className={classes.sectionGridItem} sx={{
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                      mb: "30px",
                    }}>
                      <Typography variant="h5">
                        <Star /> Send them as an attachment to your team.
                      </Typography>
                    </Grid>
                    <Grid container className={classes.sectionGridItem} sx={{
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                      mb: "30px",
                    }}>
                      <Typography variant="h5">
                        <Star /> Hold your manufacturing equipment accountable.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Card
                      variant="outlined"
                      sx={{
                        maxWidth: "100%",
                        borderRadius: 5,
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        mb: "30px",
                      }} >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          // sx={{ height: 200, width: 200, }}
                          image={report_pic}
                          alt="How PeakPro provides informative reporting to manufacturers"
                        />
                      </CardActionArea>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={8} sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' } }}>
                    <Card
                      variant="outlined"
                      sx={{
                        maxWidth: "100%",
                        borderRadius: 5,
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        mb: "30px",
                      }} >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          // sx={{ height: 200, width: 200, }}
                          image={mobile_report_pic}
                        />
                      </CardActionArea>
                    </Card>
                  </Grid>
                </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Grid container
                  spacing={2}
                  direction="row-reverse"
                  justifyContent="center"
                  alignItems="flex-start">
                  <Grid item xs={12} sm={3}>
                    <Grid container className={classes.sectionGridItem} sx={{
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                      mb: "30px",
                    }}>
                      <Typography variant="h5">
                        <Star /> Access your live + historical data from any web browser.
                      </Typography>
                    </Grid>
                    <Grid container className={classes.sectionGridItem} sx={{
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                      mb: "30px",
                    }}>
                      <Typography variant="h5">
                        <Star /> Monitor your machine data with graphs, stats, and trends.
                      </Typography>
                    </Grid>
                    <Grid container className={classes.sectionGridItem} sx={{
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                      mb: "30px",
                    }}>
                      <Typography variant="h5">
                        <Star /> Know that you hit the mark!
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={9} sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' } }}>
                    <Iframe iframe={demos["peakpro_mobile"]} />
                  </Grid>
                  <Grid item xs={12} sm={9} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Iframe iframe={demos["peakpro"]} />
              </Grid>
            </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Grid container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start">
                  <Grid item xs={12} sm={4}>
                    <Grid container className={classes.sectionGridItem} sx={{
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                      mb: "30px",
                    }}>
                      <Typography variant="h5">
                        <Star /> When a scenario happens that you care about, get notified!
                      </Typography>
                    </Grid>
                    <Grid container className={classes.sectionGridItem} sx={{
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                      mb: "30px",
                    }}>
                      <Typography variant="h5">
                        <Star /> Manage who gets which alerts, so the team stays focused.
                      </Typography>
                    </Grid>
                    <Grid container className={classes.sectionGridItem} sx={{
                      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                      mb: "20px",
                    }}>
                      <Typography variant="h5">
                        <Star /> Never miss a beat.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Grid Container
                      spacing={4}
                      display="flex"
                      flexWrap="wrap"
                      justifyContent="center"
                      alignItems="flex-start">
                      <Grid item xs={12} md={11} className={classes.sectionGridItem} sx={{
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        mb: "20px",
                        backgroundColor: '#003566',
                      }}>
                        <Typography
                          variant="h4"
                          color={'white'} sx={{ textAlign: 'center', mb: "5px", }}>
                          <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.1rem', }}>Supported Alert Platforms</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3} className={classes.sectionGridItem} sx={{
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        mb: "10px", mr: "10px", mr: "10px", mb: "10px",
                        backgroundColor: '#000814',
                      }}>
                        <Typography
                          variant="h6" color={'white'} sx={{ fontFamily: 'Manrope', fontWeight: 700, textAlign: 'center', mb: "5px", }}>
                          Teams
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3} className={classes.sectionGridItem} sx={{
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        mb: "10px", mr: "10px", mr: "10px", mb: "10px",
                        backgroundColor: '#000814',
                      }}>
                        <Typography
                          variant="h6" color={'white'} sx={{ fontFamily: 'Manrope', fontWeight: 700, textAlign: 'center', mb: "5px", }}>
                          Email
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3} className={classes.sectionGridItem} sx={{
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        mb: "10px", mr: "10px", mr: "10px", mb: "10px",
                        backgroundColor: '#000814',
                      }}>
                        <Typography
                          variant="h6" color={'white'} sx={{ fontFamily: 'Manrope', fontWeight: 700, textAlign: 'center', mb: "5px", }}>
                          Discord
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3} className={classes.sectionGridItem} sx={{
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        mb: "10px", mr: "10px", mr: "10px", mb: "10px",
                        backgroundColor: '#000814',
                      }}>
                        <Typography
                          variant="h6" color={'white'} sx={{ fontFamily: 'Manrope', fontWeight: 700, textAlign: 'center', mb: "5px", }}>
                          Slack
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={5} className={classes.sectionGridItem} sx={{
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        mb: "10px", mr: "10px", mr: "10px", mb: "10px",
                        backgroundColor: '#000814',
                      }}>
                        <Typography
                          variant="h6" color={'white'} sx={{ fontFamily: 'Manrope', fontWeight: 700, textAlign: 'center', mb: "5px", }}>
                          Cisco Webex Teams
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3} className={classes.sectionGridItem} sx={{
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        mb: "10px", mr: "10px", mr: "10px", mb: "10px",
                        backgroundColor: '#000814',
                      }}>
                        <Typography
                          variant="h6" color={'white'} sx={{ fontFamily: 'Manrope', fontWeight: 700, textAlign: 'center', mb: "5px", }}>
                          Telegram
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4} className={classes.sectionGridItem} sx={{
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        mb: "10px", mr: "10px", mr: "10px", mb: "10px",
                        backgroundColor: '#000814',
                      }}>
                        <Typography
                          variant="h6" color={'white'} sx={{ fontFamily: 'Manrope', fontWeight: 700, textAlign: 'center', mb: "5px", }}>
                          Google Hangouts
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4} className={classes.sectionGridItem} sx={{
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        mb: "10px", mr: "10px", mr: "10px", mb: "10px",
                        backgroundColor: '#000814',
                      }}>
                        <Typography
                          variant="h6" color={'white'} sx={{ fontFamily: 'Manrope', fontWeight: 700, textAlign: 'center', mb: "5px", }}>
                          Pagerduty
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
            </CustomTabPanel>
        </Box>
      </Box>
        </Grow>
    </Container>
    </ThemeProvider>
  );
};

export default Section;