import { Box, Button, Collapse, Grid, IconButton, Typography } from "@mui/material";
import termsAndCondition from '../images/PeakPro - Terms and Conditions.pdf';
import useStyles from "../styles/styles";
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const TermsAndConditions = () => {
    const classes = useStyles();
    const [pdfOpen, setPdfOpen] = useState(false);

    return (
        <Grid 
            container 
            className={classes.sectionGridContainer}
            sx={{ paddingTop: '30px' }}
        >
            <Grid
                item
                xs={12}
                md={10}
                className={classes.sectionGridItem}
                sx={{
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                }}
            >
                <Box
                    sx={{ display: "flex", justifyContent: "space-between", marginLeft: "20px", marginRight: "20px" }}
                >
                    <Box sx={{ width: "30px" }}>
                        {/* intential left side filler */}
                    </Box>
                    <Typography
                        variant="h4"
                        color={'secondary'} sx={{ textAlign: 'center', mb: "15px", width: "50%", fontFamily: 'Manrope', letterSpacing: '-0.1rem' }}
                    >
                        PeakPro Terms and Conditions
                    </Typography>
                    <Box sx={{ width: "30px" }}>
                        <IconButton
                            size="large"
                            onClick={() => setPdfOpen(!pdfOpen)}
                        >
                            {
                                pdfOpen
                                ? <ExpandLessIcon />
                                : <ExpandMoreIcon />
                            }
                        </IconButton>
                    </Box>
                </Box>
                <Collapse in={pdfOpen} >
                    <Box
                        sx={{
                            width: "100%",
                            aspectRatio: "1/1.114", // slightly shorter than A4 aspect ratio
                        }}
                    >
                        <embed 
                            src={"https://docs.google.com/gview?url=https://dev.expeditionnimrod.com/static/media/PeakPro%20-%20Terms%20and%20Conditions.4cd1a48701acc340c259.pdf&embedded=true"}
                            style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "20px",
                                border: "none",
                                WebkitOverflowScrolling: "touch",
                                overflowY: "scroll"
                            }}
                        />
                    </Box>
                </Collapse>
            </Grid>
        </Grid>
    )
}

export default TermsAndConditions;