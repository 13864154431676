import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  CardActionArea, Container,
  responsiveFontSizes,
  ThemeProvider, Grid, Typography, Box, useTheme,
} from '@mui/material';
import alex_pic from '../images/team/alex2.jpg';
import kyle_pic from '../images/team/kyle.jpg';
import layton_pic from '../images/team/layton.jpg';
import tommy_pic from '../images/team/tommy.jpg';
import useStyles from '../styles/styles';



const itemData = [

  {
    img: tommy_pic,
    name: 'Tommy Reins',
    title: 'Managing Director',
    location: 'Quad Cities, USA',
    role: 'Founder',
    link: 'https://www.linkedin.com/in/treins/',
  },
  {
    img: kyle_pic,
    name: 'Kyle Zelnio',
    title: 'Engineering Director',
    location: 'Quad Cities, USA',
    role: 'Founder',
    link: 'https://www.linkedin.com/company/expedition-nimrod/'
  },
  {
    img: layton_pic,
    name: 'Layton Nelson',
    title: 'Systems Architect',
    location: 'Quad Cities, USA',
    role: 'Founder',
    link: 'https://www.linkedin.com/company/expedition-nimrod/'
  },
  {
    img: alex_pic,
    name: 'Alex Chally',
    title: 'Software Architect',
    location: 'Jena, Germany',
    role: 'Founder',
    link: 'https://www.linkedin.com/in/alex-chally/'
  },
];

const AboutUs = () => {
  let theme = useTheme();
  theme = responsiveFontSizes(theme);
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg"
      sx={{
        // mt: "10px",
      }}
        class="gradient-background"
      >
      <Box id="about-us" >
        <Grid container className={classes.sectionGridTitleContainer} >
            <Typography variant="h3" color={'secondary'} sx={{ mt: "30px", mb: "30px" }}>
              The  <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.1rem', }}>Team</span></Typography>
        </Grid>
        <Grid container className={classes.aboutUsContainer} 
            spacing={{ xs: 4, sm: 4 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {itemData.map((item) => (
            <Grid item >
              <Card sx={{ maxWidth: 200, borderRadius: 5, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', }} >
                <CardActionArea href={item.link}>
                  <CardMedia
                    component="img"
                    sx={{ height: 200, width: 200, }}
                    image={item.img}
                    title={item.name}
                    alt="Expedition Nimrod founder pic"
                  />
                  <CardContent>
                    <Typography gutterBottom fontWeight={400} variant="h6" component="div">
                      {item.name}
                    </Typography>
                    <Typography variant="body" component="div">
                      <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.05rem', }}>{item.role}</span>
                    </Typography>
                    <Typography gutterBottom variant="body" component="div">
                      {item.title}
                    </Typography>
                    <Typography gutterBottom variant="body2" fontWeight={700} color="text.secondary">
                      {item.location}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box >
    </Container >
    </ThemeProvider>
  );
};

export default AboutUs;