import React from 'react';
import {
  Card, CardActionArea, CardMedia, Divider,
  Grid, Typography, Box, Container, Grow, Paper, Icon,
  responsiveFontSizes, ThemeProvider, useTheme
} from '@mui/material';
import useStyles from '../styles/styles'
import PropTypes from 'prop-types';
import box_diagram_pic from '../images/SiteFriendlyDiagram2.png';
import { ReactComponent as Diagram } from '../images/SiteFriendlyDiagram3.svg';
import svg_img from '../images/SiteFriendlyDiagram3.svg';

import { Element } from 'react-scroll';
import ContactModalButton from './ContactModalButton';


const Section = () => {
  let theme = useTheme();
  theme = responsiveFontSizes(theme);
  const classes = useStyles();
  const demos = {
    peakpro:
      '<iframe width="100%" height="700px" scrolling="no" frameborder="yes" src="https://demo.expeditionnimrod.com/d/e4710610-b745-4df9-9dbe-cff9d56942c1/website-snapshot-dashboard?orgId=1&refresh=1s&kiosk&output=embed"></iframe>'
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg"
        sx={{
          mt: "60px",
          // background: "linear-gradient(180deg, rgba(256,214,10,0.4),transparent)"
        }}
      // class="gradient-background">
      >
        <Box
          sx={{
            // height: 800,
          }}>
          <Grid container
            sx={{
              paddingTop: '20px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingBottom: '20px',
            }}
            spacing={3}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">
            <Grid item xs={12} sx={{
              paddingTop: '10px',
              // paddingLeft: '20px',
              // paddingRight: '20px',
              paddingBottom: '10px',
            }}>
              {/* <Grid container
                sx={{
                  paddingTop: '30px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingBottom: '30px',
                  backgroundColor: '#ededed',
                  borderRadius: '30px',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                }}
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"> */}
              <Typography
                variant="h3"
                color={'secondary'} sx={{ textAlign: 'center' }}>
                <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.1rem', }}>
                  PeakPro </span> Unlocks Your Data
              </Typography>
              {/* </Grid> */}
            </Grid>
            <Grid item xs={12} sm={8} sx={{
              paddingTop: '10px',
              // paddingLeft: '20px',
              // paddingRight: '20px',
              // paddingBottom: '10px',
            }}>
              <Grid container
                sx={{
                  paddingTop: '0px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingBottom: '15px',
                  backgroundColor: '#ededed',
                  borderRadius: '30px',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                }}
                spacing={0}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start">
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                  }}
                  src={box_diagram_pic}
                  alt="How PeakPro gives data from machines to manufacturers"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4} sx={{
              paddingTop: '10px',
              paddingLeft: '20px',
              paddingRight: '0px',
              paddingBottom: '20px',
            }}>
              <Grid container
                sx={{
                  paddingTop: '30px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  paddingBottom: '30px',
                  backgroundColor: '#ededed',
                  borderRadius: '30px',
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                }}
                spacing={0}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start">
                <Typography
                  variant="h4"
                  color={'secondary'} sx={{ textAlign: 'center', }}>
                  <span style={{ fontFamily: 'Manrope', letterSpacing: '-0.1rem', }}>Save Time + Money</span>
                </Typography>
                <Typography
                  variant="h4"
                  color={'secondary'} sx={{ mb: "30px", textAlign: 'center', }}>
                  in Two Steps
                </Typography>
                <Typography
                  variant="h5"
                  sx={{ mb: "15px", }}
                >
                  1. Connect your PeakPro device to your machines.
                </Typography>
                <Typography
                  variant="h5"
                >
                  2. Go to your PeakPro web portal.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
      </Box>
      </Container>
    </ThemeProvider >
  );
};

export default Section;